import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ContactForm from '../views/ContactForm.vue'
import SiretContactForm from '@/views/siret/signin.vue'
import Login from '../views/Login.vue'
import PasswordRequest from '../views/PasswordRequest.vue'
import Signin from '../views/user/signin.vue'
import Credential from '@/views/user/credential'
import User from '@/views/user/model'
import UserDetails from '@/views/user/details'
import UserEdit from '@/views/user/profil_edit'
import Siret from '@/views/siret/model'
import Sirets from '../views/siret/cardsList.vue'
import SiretDetails from '../views/siret/details.vue'
import Content from '@/views/content/model'
import ContentDetails from '../views/content/details.vue'
import Address from '../views/user/address.vue'
import visit from '../views/visit/router.user'
import NotFound from '@/views/error/404'
import Agreement from '@/views/user/agreement'
import Welcome from '@/views/welcome'

import loader from '@/router/loader'

Vue.use(VueRouter)
const USER = 'ROLE_USER'

const routes = [
  {
    path: '/accueil',
    name: 'home',
    component: Home,
    meta: {
      requireRole: USER
    }
  },
  {
    path: '/password-request',
    name: 'password_request',
    component: PasswordRequest
  },
  {
    path: '/contact',
    name: 'contact_form',
    component: ContactForm
  },
  {
    path: '/contact_entreprise',
    name: 'siret_contact_form',
    component: SiretContactForm,
    meta: {
      hideUserMenu: true,
      anonymous: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin
  },
  {
    path: '/credentials',
    name: 'update_credential',
    component: Credential,
    meta: {
      requireRole: USER
    }
  },
  {
    path: '/credentials/token/:token',
    name: 'update_credential_token',
    component: Credential,
    props: route => {
      const value = new User()
      value.auth({ token: route.params.token })
      return { value }
    }
  },
  {
    path: '/agreement',
    name: 'user_agreement',
    component: Agreement
  },
  ...visit,
  {
    path: '/entreprises',
    name: 'siret_cards_list',
    component: Sirets,
    props: route => {
      return { page: 1, limit: 6 }
    },
    meta: {
      requireRole: USER,
      breadcrumb: [
        {
          text: 'entreprises',
          to: '/entreprises'
        }
      ]
    }
  },
  {
    path: '/entreprise/:id',
    name: 'siret_details',
    component: loader(SiretDetails, function (vm) {
      return {
        visitPage: 1,
        visitLimit: 5,
        value: new Siret({ id: vm.$route.params.id })
      }
    }),
    meta: {
      requireRole: USER,
      breadcrumb: [
        {
          text: 'entreprises',
          to: '/entreprises'
        },
        {
          text: 'détails'
        }
      ]
    }
  },
  {
    path: '/content/:name',
    name: 'content_details',
    component: loader(ContentDetails, function (vm) {
      const value = new Content({ name: vm.$route.params.name })
      value.load()
      return { value }
    })
  },
  {
    path: '/user/:id',
    name: 'user_details',
    component: loader(UserDetails, function (vm) {
      return {
        value: new User({ id: vm.$route.params.id })
      }
    }),
    meta: {
      requireRole: USER,
      breadcrumb: [
        {
          text: 'Carnet d\'adresses',
          to: '/carnet-adresses'
        },
        {
          text: 'acteur local'
        }
      ]
    }
  },
  {
    path: '/profil/edit',
    name: 'profil_edit',
    component: loader(UserEdit, function (vm) {
      const value = new User({ id: vm.$user.id })
      value.load()
      return { value }
    }),
    meta: {
      requireRole: USER,
      breadcrumb: [
        {
          text: 'modifier mon profil'
        }
      ]
    }
  },
  {
    path: '/carnet-adresses',
    name: 'address',
    component: Address,
    meta: {
      requireRole: USER,
      breadcrumb: [
        {
          text: 'Carnet d\'adresses'
        }
      ]
    }
  },
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
    meta: {
      hideUserMenu: true,
      showToolbar: true
    }
  },
  {
    path: '*',
    name: 'not_found',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
