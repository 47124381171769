<template>
  <b-container fluid class="welcome-page">
    <b-row>
      <b-col cols="12" lg="6" class="welcome-block second">
        <b-link :href="$config.analyseUrl">
          <div>
            <img src="@/assets/pictos/graph.png" class="picto" alt="picto Analyse des besoins en compétences"/>
            <h3>Analyse des besoins<br>en compétences</h3>
            <p>Vous voulez voir les compétences spécifiques qui sont attendues par les entreprises de votre territoire ?</p>
          </div>
        </b-link>
      </b-col>
      <b-col cols="12" lg="6" class="welcome-block third">
        <b-link :to="{ name: 'login' }">
          <div>
            <img src="@/assets/pictos/account.png" class="picto" alt="picto acteur local"/>
            <h3>Vous êtes un acteur local ?</h3>
            <p>Vous souhaitez saisir les besoins en compétences des entreprises que vous visitez et suivre vos prospects ? <span class="bold">Accédez à Votre espace</span></p>
          </div>
        </b-link>
      </b-col>
    </b-row>
    <h2>Bienvenue sur le site d’Identification des Besoins en Compétences des Entreprises de la région Centre-Val de Loire : Ensemble, on est plus fort !</h2>
    <b-container>
      <p>
        <span class="welcome-logos">
          <img class="logo_marque1" src="@/assets/logo_page_daccueil.png" alt="logo Région Centre-Val de Loire, Ministère du travail Haut-commissariat aux compétences et à l'inclusion par l'emploi, Investir l'avenir, Investir dans vos compétences"/>
        </span>
        <span class="welcome-logos">
          <span class="logo_marque1">
            Projet cofinancé dans le cadre du Plan d'investissement dans les compétences.
          </span>
          <span class="logo_marque2" style="height: 0;"></span>
        </span>
      </p>
    </b-container>
    <b-row class="welcome-text">
      <b-col>
        <b-container>
          <b-row>
            <b-col cols="12">
              <div>
                <p>Ce site vous permet d'observer les compétences attendues et particulières à votre territoire : il s'agit d'y voir plus clair sur les besoins des entreprises : quelles sont les compétences nécessaires à l'exercice des emplois ? Y aurait-il des spécificités locales dans l'exercice de métiers pourtant très connus ?</p>
                <p>Pour les acteurs locaux qui interviennent auprès des entreprises, ce site vous permet de saisir les besoins en compétences que les entreprises vous auront indiqués.</p>
                <p>Vous pourrez de plus voir si une entreprise a récemment été visitée ainsi que le motif de la visite.</p>
                <p>En observant finement les besoins des entreprises, l'action au service des entreprises pourra s'améliorer.</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div>
                <p>Le projet « Identification des Besoins en Compétences des Entreprises » est une expérimentation régionale portée en région Centre-Val de Loire jusqu'en 2022.</p>
                <p>Ce projet est financé dans le cadre du Pacte Régional d'Investissement dans les Compétences a été initié par l'Etat, la Région et les partenaires sociaux. Il est porté opérationnellement au sein de l'Observatoire Régional du GIP Alfa Centre-Val de Loire. Son comité de pilotage est composé du Conseil régional, de la Direccte, du MEDEF, de l’U2P et de Pôle Emploi.</p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="2" class="welcome-block no-anim stats">
        <div>
          <b>{{ users }}</b>
          <p>Acteurs enregistrés</p>
        </div>
      </b-col>
      <b-col cols="12" lg="2" class="welcome-block no-anim stats">
        <div>
          <b>{{ visit_trades }}</b>
          <p>Fiches métiers saisies</p>
        </div>
      </b-col>
      <b-col cols="12" lg="4" class="welcome-block first">
        <b-link :to="{ name: 'siret_contact_form' }">
          <div>
            <img src="@/assets/pictos/entreprise.png" class="picto" alt="picto entreprise"/>
            <h3>Vous êtes une entreprise ?</h3>
            <p> Vous êtes une entreprise et vous souhaitez contacter un conseiller entreprise / acteur local sur les champs du développement économique, de l’emploi, de la formation et de l’orientation professionnelles ? <span class="bold">accédez au formulaire de contact</span></p>
          </div>
        </b-link>
      </b-col>
      <b-col cols="12" lg="4" class="welcome-block stats">
        <b-link :href="$config.diagnosticUrl" v-if="$config.diagnosticUrl">
          <div>
            <img src="@/assets/pictos/map.png" class="picto" alt="picto données statistiques"/>
            <h3>Diagnostic en continu</h3>
            <p>Découvrir des données statistiques régionales sur les publics du territoire.</p>
          </div>
        </b-link>
        <div v-else>
          <div style="opacity: 0.3;">
            <img src="@/assets/pictos/map.png" class="picto" alt="picto données statistiques"/>
            <h3>Diagnostic en continu</h3>
            <p>Découvrir des données statistiques régionales sur les publics du territoire.</p>
          </div>
          <p>Bientôt disponible</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Model from '@/views/model'

export default {
  name: 'gip.welcoe',
  data () {
    return {
      users: 0,
      visit_trades: 0
    }
  },
  async mounted () {
    try {
      const { data } = await Model.api.then(api => api.get('/stats'))
      Object.assign(this, data)
    } catch {
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/custom-vars.scss';
.stats {
  display: flex;
  div {
    margin: auto;
    b {
      font-size: 3em;
    }
  }
}
.welcome-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-blue-dark;
  font-size: 17px;
  padding: 0 25px;
  font-family: 'Fira';
}
.logo_marque1 {
  max-width: 1140px;
  width: 100%;
}
.logo_marque2 {
  height: 111px;
}
@media(min-width: 900px) {
  .stats div b {
    font-size: 3em;
  }
  .welcome-logos {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-evenly;
  }
}
</style>
