<template>
  <b-container id="gip" fluid :class="mainClasses">
    <gip-nav :showMenu="showUserMenu"></gip-nav>
    <b-container fluid class="gip-main" :class="{ 'reduced-menu': reduced }">
      <b-row align-h="end" v-if="isAuth && showUserMenu">
        <b-col id="layout-user" cols="2">
          <user-menu @reduced="reduceMenu"></user-menu>
        </b-col>
        <b-col cols="12" xl="10" id="layout-content">
          <b-breadcrumb :items="breadcrumb"/>
          <router-view/>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <router-view/>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="!isAuth" style="margin-bottom: 110px;"></div>
    <gip-footer v-if="!isAuth || $route.meta.showToolbar"></gip-footer>
  </b-container>
</template>
<style lang="scss">
  @import '@/assets/custom-vars.scss';
  @import '~bootstrap/scss/bootstrap.scss';
  @import '~bootstrap-vue/src/index.scss';
  @import '@/assets/custom.scss';
</style>
<script>
import Nav from '@/views/user/nav'
import Footer from '@/views/footer'
import model from '@/views/model.mixin'
import UserMenu from '@/views/UserMenu'

export default {
  name: 'gip',
  mixins: [model('$user')],
  components: { GipNav: Nav, GipFooter: Footer, UserMenu },
  data () {
    this.$user.on('update', () => {
      this.isAuth = this.$user.isAuth
    })
    return {
      isAuth: this.$user.isAuth,
      reduced: false
    }
  },
  methods: {
    reduceMenu () {
      this.reduced = !this.reduced
    }
  },
  computed: {
    mainClasses () {
      return [
        !this.isAuth || this.$route.meta.anonymous ? 'anonymous-background' : '',
        this.$route.name
      ]
    },
    breadcrumb () {
      return (this.$route.meta && this.$route.meta.breadcrumb) ? this.$route.meta.breadcrumb.map(path => {
        return Object.assign({}, path, {
          to: path.to && (typeof path.to === 'function' ? path.to(this.$route) : path.to)
        })
      }) : []
    },
    showUserMenu () {
      if (!this.isAuth || this.$route.meta.hideUserMenu) {
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss">
#gip, body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}
@media screen and (max-width: 900px) {
  #gip.welcome nav.footer,
  #gip.contact_form nav.footer,
  #gip.signin nav.footer {
    position: relative !important;
  }
}
#gip.welcome {
  overflow-x: hidden;
  nav.footer {
    position: fixed;
    z-index: 100;
  }
}
</style>
