<template>
  <div>
    <h1>Contacter un acteur local</h1>
    <template v-if="sent">
      <b-alert show>
        <div class="d-flex align-items-center justify-content-between">
          <span>Merci ! Votre message a été envoyé. Et un accusé réception vous a été adressé sur votre adresse e-mail.</span>
          <b-btn variant="primary" :to="{ name: 'welcome' }">Accueil</b-btn>
        </div>
      </b-alert>
    </template>
    <template v-else>
      <b-alert show>
        Vous pouvez remplir ce formulaire pour connaître les coordonnées des acteurs locaux qui sont sur votre territoire ou au niveau régional :
      </b-alert>
      <siret-contact-form @submit="showUsersChoice" v-model="siretContact" v-if="!showContacts && !showMessage"></siret-contact-form>
      <user-table :orders="orders" :filters="filters" :fields="fields" v-if="showContacts" @contact="sendContact" ref="userTable">
        <template #none>
          <div>
            <p>
              Vous pouvez contacter
              <ul>
                <li>L’équipe Identification des Besoins en Compétences des Entreprises au 02 38 24 43 ou par mail <a href="mailto:identificationdescompetences@alfacentre.org">identificationdescompetences@alfacentre.org</a></li>
                <li>Les services de Pôle Emploi pour vos projets de recrutement au 3995</li>
              </ul>
              Vous pouvez consulter
              <ul>
                <li>Le site <a href="https://www.devup-centrevaldeloire.fr">https://www.devup-centrevaldeloire.fr</a> pour vos projets de développement économique</li>
                <li>Le site de la Direccte Centre-Val de Loire <a href="http://centre-val-de-loire.direccte.gouv.fr/Ma-ressource-RH">http://centre-val-de-loire.direccte.gouv.fr/Ma-ressource-RH</a> pour vos démarches RH</li>
              </ul>
              Ou laissez-nous directement un message ci-dessous.
              <b-form>
                <b-form-group label="Message*">
                  <b-form-textarea v-model="siretContact.message" rows="4" required></b-form-textarea>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="siretContact.acceptNotifications">
                    Acceptez-vous d’être informé des événements dans le cadre de ce projet ?
                  </b-form-checkbox>
                </b-form-group>
                <b-button @click="onSave" variant="primary" :disabled="!isValid">Valider</b-button>
              </b-form>
            </p>
          </div>
        </template>
      </user-table>
      <b-form v-if="showMessage">
        <b-form-group label="Message*">
          <b-form-textarea v-model="siretContact.message" rows="4" required></b-form-textarea>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="siretContact.acceptNotifications">
            Acceptez-vous d’être informé des événements dans le cadre de ce projet ?
          </b-form-checkbox>
        </b-form-group>
        <b-button @click="onSave" variant="primary" :disabled="!isValid">Valider</b-button>
      </b-form>
    </template>
  </div>
</template>
<style lang="scss">
@import '@/assets/custom-vars.scss';
.alert {
  a:not(.btn) {
    color: $color-blue-light;
  }
  .btn {
    margin: 0.4em 0.4em 0 0;
  }
}
.siret_contact_form .alert legend,
.siret_contact_form .alert label {
  color: white;
}
</style>
<script>
import Siret from '@/views/siret/model'
import SiretContactForm from '@/views/siret/contactForm'
import UserTable from '@/views/user/table'
import Model from '@/views/model'

export default {
  name: 'gip.signin.siret',
  components: {
    SiretContactForm,
    UserTable
  },
  data () {
    return {
      sent: false,
      siretContact: {
        siret: new Siret(),
        email: '',
        lastname: '',
        firstname: '',
        phone: '',
        message: '',
        acceptNotifications: false,
        user: null
      },
      simpleText: '',
      showContacts: false,
      showMessage: false,
      fields: [
        { key: 'lastname', label: 'Nom', sortable: true },
        { key: 'firstname', label: 'Prénom', sortable: true },
        { key: 'organisations', label: 'Types de structure', sortable: true },
        { key: 'structure', label: 'Nom de la structure', sortable: true },
        { key: 'mission.name', label: 'Champ d\'intervention', sortable: true },
        { key: 'contact', label: '' }
      ],
      filters: [{
        type: '\\App\\Model\\Filter\\EqualsFilter',
        field: 'enabled',
        value: JSON.stringify(true)
      },
      {
        type: '\\App\\Model\\Filter\\EqualsFilter',
        field: 'companyVisit',
        value: JSON.stringify(true)
      }],
      orders: [{
        column: 'lastname',
        direction: 'ASC'
      }]
    }
  },
  methods: {
    showUsersChoice () {
      this.filters.push({
        type: '\\App\\Model\\Filter\\OrFilter',
        filters: [{
          type: '\\App\\Model\\Filter\\LocationRadiusFilter',
          field: 'cityPostcode.city',
          location: JSON.stringify(this.siretContact.siret.cityPostcode.city.id),
          radius: JSON.stringify(50)
        }, {
          type: '\\App\\Model\\Filter\\EqualsFilter',
          field: 'regionalActor',
          value: JSON.stringify(true)
        }]
      })
      this.showContacts = true
    },
    sendContact (value) {
      this.siretContact.user = value
      this.showContacts = false
      this.showMessage = true
    },
    async onSave () {
      this.siretContact.siret = Number(this.siretContact.siret.id)
      await Model.api.then(api => api.post('/contact-request', this.siretContact))
      this.$emit('sent')
      this.sent = true
    }
  },
  computed: {
    isValid () {
      return this.siretContact.siret.id && this.siretContact.message.trim().length
    }
  }
}
</script>
