<script>
import Siret from '@/views/siret/model'
import Selector from '@/views/selector'

export default {
  name: 'gip.selector.siret_siret',
  extends: Selector,
  props: {
    value: Siret,
    field: {
      default: () => 'siret'
    },
    type: {
      default: () => Siret
    }
  }
}
</script>
