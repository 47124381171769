<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-group label="SIRET*">
      <siret-select v-model="value.siret" required></siret-select>
    </b-form-group>
    <b-row v-if="value.siret.name">
      <b-col>
        <p class="contact_siret_name">
          <span class="bold">Entreprise : </span>{{ value.siret.name }}
        </p>
      </b-col>
    </b-row>
    <b-form-group label="Adresse email*">
      <b-input v-model="value.email" type="email" required />
    </b-form-group>
    <b-form-group label="Téléphone*">
      <b-input v-model="value.phone" required />
    </b-form-group>
    <b-form-group label="Nom*">
      <b-input v-model="value.lastname" required />
    </b-form-group>
    <b-form-group label="Prénom*">
      <b-input v-model="value.firstname" required />
    </b-form-group>

    <b-button type="submit" variant="primary" :disabled="!isValid">Valider</b-button>
  </b-form>
</template>
<script>
import SiretSelect from '@/views/siret/selectSiret'
import { required, checkPattern } from '@/views/validation/validator'

export default {
  name: 'gip.siret.contact_form',
  props: {
    value: Object
  },
  watch: {
    'value.siret.id': function () {
      this.value.siret.load()
    }
  },
  components: {
    SiretSelect
  },
  methods: {
    async onSubmit () {
      if (this.value.siret.loading) {
        await this.value.siret.loading
      }
      this.$emit('submit', this.value)
    }
  },
  computed: {
    isValid () {
      const requiredFields = [
        'siret',
        'email',
        'phone',
        'firstname',
        'lastname'
      ]
      let message = required(requiredFields, this.value)
      if (!message) {
        message = checkPattern(this.value.email, true, /\S+@\S+\.\S+/)
      }
      return !message
    }
  }
}
</script>
