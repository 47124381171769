<template>
  <b-container fluid>
    <p class="bold home_title">Mon espace - Bienvenue {{ $user.firstname }} {{ $user.lastname }}</p>
    <header class="bloc-title">
      <h2 class="bloc-title-content">
        <b-link :to="{ name: 'visit_list' }">Mes visites</b-link>
      </h2>
    </header>
    <b-row>
      <b-col>
        <b-spinner v-if="loading" variant="primary" centered/>
        <carousel :navigationEnabled="true" :perPage="1" :perPageCustom="[[1200, 3]]" v-else-if="visits.length">
          <slide v-for="(visit, i) in visits" :key="visit.id">
            <visit-card v-model="visits[i]"/>
          </slide>
        </carousel>
        <b-alert v-else show>Vous n'avez pas de visites pour le moment, <b-link to="/visit">ajouter une nouvelle visite ?</b-link></b-alert>
      </b-col>
    </b-row>
    <header class="bloc-title">
      <h2 class="bloc-title-content">Les dernières entreprises visitées par les acteurs locaux</h2>
    </header>
    <b-row>
      <b-col>
        <siret-table :filters="siretFilters" :limit="5" :fields="siretFields" :default-orders="siretOrders">
          <template #actions="{ siret }">
            <b-button-group>
              <b-button :to="{ name: 'siret_details', params: { id: siret.id } }" variant="primary" size="sm">
                <unicon name="eye"></unicon>
              </b-button>
            </b-button-group>
          </template>
        </siret-table>
      </b-col>
    </b-row>
    <header class="bloc-title">
      <h2 class="bloc-title-content">Les acteurs locaux à proximité</h2>
    </header>
    <GmapMap
      :center="{lat:47.6221073, lng:-0.6521761}"
      :zoom="7"
      map-type-id="terrain"
      style="width: 100%; height: 400px"
    >
      <GmapCluster @click="cluster">
        <GmapMarker
          ref="gmap"
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          @click="toggleInfoWindow(m, index)"
        />
        <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
          <div class="gmap-info" v-if="infoUsers.length">
            <template v-for="(infoUser, i) in infoUsers">
              <user-card v-model="infoUsers[i]" :key="infoUsers[i].id"/>
            </template>
          </div>
        </GmapInfoWindow>
      </GmapCluster>
    </GmapMap>
  </b-container>
</template>
<style scoped>
.alert a {
  color: white;
  text-decoration: underline;
  :visited: white;
}
</style>
<script>
import axios from 'axios'
import Visit from './visit/model'
import VisitCard from './visit/card'
import SiretTable from './siret/table'
import User from './user/model'
import UserCard from '@/views/user/card'
import { gmapApi } from 'vue2-google-maps'
import Error from '@/views/error/mixin'

export default {
  name: 'gip.home',
  data () {
    let siretFilters = [{
      type: '\\App\\Model\\Filter\\VisitedFilter'
    }]
    if (!this.$user.regionalActor) {
      siretFilters.push({
        type: '\\App\\Model\\Filter\\LikeFilter',
        field: 'cityPostcode.city.employmentZone.name',
        value: JSON.stringify(`%${this.$user.cityPostcode.city.employmentZone.name}%`)
      })
    }
    return {
      visits: [],
      users: [],
      loading: false,
      userLoading: false,
      siretFilters: siretFilters,
      siretFields: [
        { key: 'name', label: 'Entreprise', sortable: true },
        { key: 'cityPostcode.city.employmentZone.name', label: 'Territoire', sortable: true },
        { key: 'address', label: 'Adresse', sortable: true },
        { key: 'lastVisitDate', label: 'Dernière visite', sortable: true },
        { key: 'actions', label: '' }
      ],
      siretOrders: [
        { column: '#v.date', direction: 'DESC' }
      ],
      markers: [],
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoUsers: []
    }
  },
  computed: {
    google: gmapApi
  },
  mixins: [Error],
  components: { VisitCard, SiretTable, UserCard },
  async mounted () {
    try {
      this.loading = Visit.search({
        page: 1,
        limit: 9,
        filters: [{
          type: '\\App\\Model\\Filter\\EqualsFilter',
          field: 'user',
          value: this.$user.id
        }],
        orders: [{
          column: 'date',
          direction: 'DESC'
        }],
        serializers: ['short']
      })
      const { results } = await this.loading
      this.visits.splice(0, this.visits.length, ...results)
    } catch (err) {
      this.handleError(err)
    }
    this.loading = false
    try {
      this.userLoading = User.search({
        page: 1,
        limit: 100,
        filters: [{
          type: '\\App\\Model\\Filter\\EqualsFilter',
          field: 'enabled',
          value: JSON.stringify(true)
        }]
      })
      const { results } = await this.userLoading
      this.users.splice(0, this.users.length, ...results)
      let i = 0
      while (this.users[i]) {
        await this.getCoordinates(this.users[i])
        i++
      }
    } catch (err) {
      this.handleError(err)
    }
    this.userLoading = false
  },
  methods: {
    getCoordinates (element) {
      return axios.get('https://api-adresse.data.gouv.fr/search', { params: { q: element.address + ' ' + element.cityPostcode.name } }).then(({ data }) => {
        if (data.features.length > 0) {
          if (data.features[0].geometry) {
            this.markers.push({
              position: {
                lat: data.features[0].geometry.coordinates[1],
                lng: data.features[0].geometry.coordinates[0]
              },
              user: element,
              draggable: false,
              clickable: true
            })
          }
        }
      })
    },
    cluster (cluster) {
      const markers = cluster.getMarkers()
      const users = this.markers
        .filter(m => {
          return markers.some(gm => m.position.lat === gm.position.lat() && m.position.lng === gm.position.lng())
        })
        .map(m => m.user)
      this.infoUsers.splice(0, this.infoUsers.length, ...users)
      this.infoWindowPos = cluster.getCenter()
      if (this.currentMidx === cluster.getMarkers()) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = cluster.getMarkers()
      }
    },
    toggleInfoWindow (marker, idx) {
      this.infoUsers.splice(0, this.infoUsers.length, marker.user)
      this.infoWindowPos = marker.position
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    }
  }
}
</script>
