<template>
  <b-container fluid>
    <header class="bloc-title">
      <h1 class="bloc-title-content">Mes visites</h1>
      <b-button-toolbar>
        <b-button to="/visit" variant="primary">
          <unicon name="plus"></unicon>
        </b-button>
      </b-button-toolbar>
    </header>
    <b-row>
      <b-col>
        <b-form @submit.prevent="onSubmitFilters">
          <b-row>
            <b-col>
              <b-form-group label="Entreprise">
                <b-input v-model="search.siret"/>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Territoire">
                <b-input v-model="search.employmentZone"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <visit-table :filters="filters" :limit="10" :fields="fields"/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import VisitTable from '@/views/visit/table'

export default {
  name: 'gip.visit.list',
  props: {
    page: Number,
    limit: Number
  },
  components: { VisitTable },
  data () {
    return {
      search: {
        siret: '',
        employmentZone: ''
      },
      filters: [{
        type: '\\App\\Model\\Filter\\EqualsFilter',
        field: 'user',
        value: this.$user.id
      }],
      fields: ['date', 'siret.cityPostcode.city.employmentZone.name', 'siret', 'purposes', 'trade', 'lastVisitDate', 'actions']
    }
  },
  watch: {
    search: {
      handler: function () {
        this.onSubmitFilters()
      },
      deep: true
    }
  },
  methods: {
    async onSubmitFilters () {
      this.page = 1
      this.filters.splice(
        1,
        this.filters.length,
        {
          type: '\\App\\Model\\Filter\\LikeFilter',
          field: 'siret.name',
          value: JSON.stringify(`%${this.search.siret}%`)
        },
        {
          type: '\\App\\Model\\Filter\\LikeFilter',
          field: 'siret.cityPostcode.city.employmentZone.name',
          value: JSON.stringify(`%${this.search.employmentZone}%`)
        }
      )
    }
  }
}
</script>
