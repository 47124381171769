import Unicon from 'vue-unicons'
import { starFull } from '@/views/icons/star_full'

import {
  uniBell,
  uniBuilding,
  uniCopy,
  uniEnvelopeAlt,
  uniEye,
  uniEyeSlash,
  uniFolderPlus,
  uniHome,
  uniMinus,
  uniPlusCircle,
  uniQuestionCircle,
  uniPen,
  uniPhone,
  uniPlus,
  uniSearch,
  uniSuitcase,
  uniStar,
  uniTrashAlt,
  uniScience,
  uniExclamationTriangle,
  uniFileDownload,
  uniTimesCircle,
  uniBan

} from 'vue-unicons/src/icons'

Unicon.add([
  starFull,
  uniBuilding,
  uniCopy,
  uniEnvelopeAlt,
  uniEye,
  uniEyeSlash,
  uniFolderPlus,
  uniHome,
  uniMinus,
  uniQuestionCircle,
  uniPlusCircle,
  uniPen,
  uniPhone,
  uniPlus,
  uniSearch,
  uniSuitcase,
  uniStar,
  uniTrashAlt,
  uniScience,
  uniExclamationTriangle,
  uniFileDownload,
  uniTimesCircle,
  uniBan,
  uniBell
])

export default Unicon
