<template>
  <b-card :title="siret.name" class="visit-card">
    <template v-slot:header>
      {{ visitDate }}
      <span class="visit-diff recent" v-if="lastVisitDate <= 3" title="Il y a moins de 3 mois"></span>
      <span class="visit-diff middle" v-if="lastVisitDate <= 6 && lastVisitDate > 3" title="Il y a moins de 6 mois"></span>
      <span class="visit-diff old" v-if="lastVisitDate > 6" title="Il y a plus de 6 mois"></span>
    </template>
    <b-row>
      <b-col>
        <ul>
          <li><span class="bold">Objets de la visite : </span>{{ purposes.map(p => p.label).join(', ') }}</li>
          <li><span class="bold">Compte rendu de la visite : </span>{{ comment | truncate }}</li>
        </ul>
      </b-col>
    </b-row>
    <template v-slot:footer>
      <b-link :to="'/visite/'+id">Voir la fiche complète de la visite</b-link>
    </template>
  </b-card>
</template>
<style scoped>
.visit-diff {
  position: absolute;
  top: 5px;
}
</style>
<script>
import Visit from '@/views/visit/model'
import format from 'date-fns/format'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'

export default {
  name: 'gip.visit.card',
  props: {
    value: Visit
  },
  data () {
    this.value.on('update', v => Object.assign(this, v.marshall()))
    return Object.assign({ 'lastVisitDate': 10 }, this.value.marshall())
  },
  mounted () {
    this.getLastVisitDate(this.value.siret)
  },
  methods: {
    async getLastVisitDate (siret) {
      const loading = Visit.search({
        page: 1,
        limit: 1,
        filters: [
          {
            type: '\\App\\Model\\Filter\\EqualsFilter',
            field: 'siret',
            value: JSON.stringify(siret.id)
          }
        ],
        orders: [{
          column: 'date',
          direction: 'DESC'
        }],
        serializers: ['date', 'id']
      })
      const result = await loading
      if (result.results[0]) {
        this.lastVisitDate = differenceInCalendarMonths(new Date(), new Date(result.results[0].date))
      } else {
        this.lastVisitDate = 10
      }
    }
  },
  computed: {
    visitDate: function () {
      return format(new Date(this.value.date), 'dd/MM/yyyy')
    }
  },
  filters: {
    truncate: function (value, l = 200) {
      return value && typeof value === 'string' && value.length > 200
        ? value.substring(0, 200) + '...'
        : value || ''
    }
  }
}
</script>
