<template>
  <b-container fluid>
    <header class="bloc-title">
      <h1 class="bloc-title-content">Entreprises</h1>
    </header>
    <b-row>
      <b-col>
        <b-form>
          <b-row>
            <b-col cols="12" xl="6">
              <b-form-group label="Entreprise">
                <b-input v-model="filters.name"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6">
              <b-form-group label="Siret">
                <b-input v-model="filters.siret"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xl="6">
              <b-form-group>
                <template v-slot:label>
                  Naf732<Tips title="Nomenclature d’activités française en 732 postes, saisissez un code"/>
                </template>
                <b-input v-model="filters.naf"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6">
              <b-form-group label="Territoire">
                <b-input v-model="filters.employmentZone"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-spinner v-if="loading"/>
    <template v-else-if="sirets.length > 0">
      <b-card-group columns>
        <siret-card v-for="(siret, i) in sirets" v-model="sirets[i]" :key="siret.id" :link="true"/>
      </b-card-group>
      <b-pagination
        pills
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        aria-controls="my-table"
        @input="search"/>
    </template>
    <b-alert v-else show>Aucune entreprise à afficher.</b-alert>
  </b-container>
</template>
<script>
import Siret from './model'
import SiretCard from './card'
import Error from '@/views/error/mixin'
import Tips from '@/views/tips'
import axios from 'axios'
const CancelToken = axios.CancelToken

export default {
  name: 'gip.siret.cardsList',
  props: {
    page: Number,
    limit: Number
  },
  mixins: [Error],
  components: { SiretCard, Tips },
  data () {
    return {
      sirets: [],
      count: 0,
      loading: false,
      filters: {
        name: '',
        siret: '',
        naf: '',
        employmentZone: ''
      },
      cancel: null
    }
  },
  watch: {
    filters: {
      handler () {
        this.search()
      },
      deep: true
    }
  },
  methods: {
    async search () {
      if (this.cancel && 'cancel' in this.cancel) {
        this.cancel.cancel()
      }
      this.cancel = CancelToken.source()
      try {
        const filters = []
        if (this.filters.name.trim().length) {
          filters.push({
            type: '\\App\\Model\\Filter\\LikeFilter',
            field: 'name',
            value: JSON.stringify(`%${this.filters.name}%`)
          })
        }
        if (this.filters.siret.trim().length) {
          filters.push({
            type: '\\App\\Model\\Filter\\LikeFilter',
            field: 'siret',
            value: JSON.stringify(`%${this.filters.siret}%`)
          })
        }
        if (this.filters.naf.trim().length) {
          filters.push({
            type: '\\App\\Model\\Filter\\LikeFilter',
            field: 'naf.code',
            value: JSON.stringify(`%${this.filters.naf}%`)
          })
        }
        if (this.filters.employmentZone.trim().length) {
          filters.push({
            type: '\\App\\Model\\Filter\\LikeFilter',
            field: 'cityPostcode.city.employmentZone.name',
            value: JSON.stringify(`%${this.filters.employmentZone}%`)
          })
        }
        this.loading = Siret.search({
          page: this.page,
          limit: this.limit,
          filters,
          orders: [{
            column: 'name',
            direction: 'ASC'
          }],
          cancelToken: this.cancel.token
        })
        const { results, count } = await this.loading
        this.count = count
        this.sirets.splice(0, this.sirets.length, ...results)
      } catch (err) {
        this.handleError(err)
      }
      this.loading = false
    }
  },
  async mounted () {
    this.search()
  }
}
</script>
