import Visit from '@/views/visit/model'
import Siret from '@/views/siret/model'
import VisitTrade from '@/views/visit_trade/model'
import Visits from '@/views/visit/myList.vue'
import VisitForm from '@/views/visit/Form.vue'
import VisitDetails from '@/views/visit/details.vue'
import VisitTradeStepper from '@/views/visit_trade/steps_4'

import loader from '@/router/loader'

const slices = {
  list: {
    text: 'mes visites',
    to: { name: 'visit_list' }
  },
  create: {
    text: 'nouvelle',
    active: true
  },
  view: {
    text: 'détails',
    to: function (route) {
      return {
        name: 'visit_details',
        params: {
          id: route.params.visitId || route.params.id
        }
      }
    }
  },
  edit: {
    text: 'modifier',
    to: function (route) {
      return {
        name: 'visit_edit',
        params: {
          id: route.params.id
        }
      }
    }
  },
  trade: {
    edit: {
      text: 'Modifier',
      active: true
    },
    create: {
      text: 'ajouter',
      active: true
    }
  }
}
const role = 'ROLE_USER'

const routes = [
  {
    path: '/mes-visites',
    name: 'visit_list',
    component: Visits,
    props: { page: 1, limit: 10 },
    meta: {
      requireRole: role,
      breadcrumb: [slices.list]
    }
  },
  {
    path: '/visits/:page/limit/:limit',
    name: 'visit_list_page',
    component: Visits,
    meta: {
      requireRole: role,
      breadcrumb: [slices.list]
    }
  },
  {
    path: '/visit',
    name: 'visit',
    component: loader(VisitForm, function (vm) {
      return {
        value: new Visit({ user: vm.$user })
      }
    }),
    meta: {
      requireRole: role,
      breadcrumb: [slices.list, slices.create]
    }
  },
  {
    path: '/visit/entreprise/:id',
    name: 'visit_from_siret',
    component: loader(VisitForm, function (vm) {
      const siret = new Siret({ id: vm.$route.params.id })
      siret.load()
      return {
        value: new Visit({
          user: vm.$user,
          siret
        })
      }
    }),
    meta: {
      requireRole: role,
      breadcrumb: [slices.list, slices.create]
    }
  },
  {
    path: '/visite/:id',
    name: 'visit_details',
    component: loader(VisitDetails, function (vm) {
      return {
        value: new Visit({ id: vm.$route.params.id })
      }
    }),
    meta: {
      requireRole: role,
      breadcrumb: [slices.list, slices.view]
    }
  },
  {
    path: '/visit/:id/edit',
    name: 'visit_edit',
    component: loader(VisitForm, function (vm) {
      return {
        value: new Visit({ id: vm.$route.params.id })
      }
    }),
    meta: {
      requireRole: role,
      breadcrumb: [slices.list, slices.edit]
    }
  },
  {
    path: '/visit/:id/duplicate',
    name: 'visit_duplicate',
    component: loader(VisitForm, function (vm) {
      return {
        value: new Visit({ fromId: vm.$route.params.id })
      }
    }),
    meta: {
      requireRole: role,
      breadcrumb: [slices.list, slices.edit]
    }
  },
  {
    path: '/visite/:visitId/metier/:id/modifier',
    name: 'visit_trade_edit',
    component: loader(VisitTradeStepper, function (vm) {
      const value = new VisitTrade({ visit: Number(vm.$route.params.visitId), id: Number(vm.$route.params.id) })
      value.load()
      return { value }
    }),
    meta: {
      requireRole: role,
      breadcrumb: [slices.list, slices.view, slices.trade.edit]
    }
  },
  {
    path: '/visite/:id/metiers/ajouter',
    name: 'visit_trade_add',
    component: loader(VisitTradeStepper, function (vm) {
      const value = new VisitTrade({ visit: Number(vm.$route.params.id) })
      value.load()
      return { value }
    }),
    meta: {
      requireRole: role,
      breadcrumb: [slices.list, slices.view, slices.trade.create]
    }
  }
]

export default routes
