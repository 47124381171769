import '@/views/version'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import authorize from '@/router/authorize'
import agreement from '@/router/agreement'
import BootstrapVue from 'bootstrap-vue'
import User from '@/views/user/model'
import axios from 'axios'
import Model from '@/views/model'
import Unicon from '@/views/main.icons'
import Uniq from '@/views/uniq.plugin'
import Notify from '@/views/notify.plugin'
import VueCarousel from 'vue-carousel'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import VueGtag from 'vue-gtag'

Vue.use(BootstrapVue)
Vue.use(Unicon)
Vue.use(Uniq)
Vue.use(Notify)
Vue.use(VueCarousel)

Vue.component('GmapCluster', GmapCluster)

Vue.config.productionTip = false
const user = new User()
router.beforeEach(authorize(user))
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    return next({ name: 'not_found', params: { '0': 0 } })
  }
  next()
})
router.beforeEach(agreement(user))
Vue.prototype.$config = {}
Vue.prototype.$user = user

axios.get('/config.json')
  .then(({ data }) => {
    Object.assign(Vue.prototype.$config, data)
    Model.api = Promise.resolve(axios.create({
      baseURL: data.api.url
    }))
    if (data && data.google && data.google.analitycs) {
      Vue.use(VueGtag, {
        config: { id: data.google.analitycs }
      })
    }
    if (data && data.google && data.google.maps) {
      Vue.use(VueGoogleMaps, {
        load: {
          key: data.google.maps
        }
      })
    }
  }).then(() => {
    user.auth()
  })
  .then(async () => {
    try {
      await user.me()
    } catch (err) {
      if (err instanceof Promise) {
        return err.catch(
          err => {
            if (!user.onAgreementError(err)) {
              user.auth(null)
            }
          }
        )
      } else {
        user.auth(null)
      }
    }
  })
  .then(() => {
    new Vue({
      router,
      render: h => h(App)
    }).$mount('#gip')
  })
