<template>
  <b-container>
    <h1>Mot de passe oublié</h1>
    <b-row>
      <b-col>
        <b-form @submit.prevent="onSubmit">
          <b-form-group label="Adresse email*">
            <b-input v-model="email" type="email" placeholder="@" required/>
          </b-form-group>
          <b-btn type="submit" variant="primary">
            <template v-if="state.logging"><b-spinner small type="grow"></b-spinner></template>
            <template v-else>Valider</template>
          </b-btn>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import model from '@/views/model.mixin'

export default {
  name: 'gip.password_request',
  mixins: [model('$user')],
  data () {
    return {
      email: ''
    }
  },
  methods: {
    onSubmit () {
      this.$user
        .passwordRequest({ email: this.email })
        .then(
          () => {
            this.$notify({
              content: `Un email vous a été envoyé afin de définir un nouveau mot de passe`,
              title: 'Mot de passe oublié'
            })
            this.$router.push({ name: 'welcome' })
          },
          err => {
            this.handlePromiseError(err, err => {
              if (err.response && err.response.status === 404) {
                this.$bvToast.toast('Email introuvable', {
                  title: 'Erreur',
                  variant: 'danger',
                  toaster: 'b-toaster-top-center',
                  solid: true
                })
                return true
              } else {
                this.handleError(err)
              }
            })
          }
        )
    }
  }
}
</script>
<style>
.login-container {
  max-width: 500px;
  margin-top: 20%;
}
</style>
