<template>
  <b-navbar toggleable="lg" v-out="hideNotif">
    <template v-if="$user.isAuth && showMenu">
      <div class="nav-button" id="toggle-user-nav" @click="toggleUserNav" v-bind:class="{ active: isUserNavOpen }" v-if="!isMainNavOpen">
        <span class="top"></span>
        <span class="middle"></span>
        <span class="bottom"></span>
      </div>
      <div v-bind:class="{ open: isUserNavOpen }" id="user-nav">
        <user-menu @toggle="toggleUserNav"></user-menu>
      </div>
      <div class="nav-button" @click="toggleNotif" id="mobile-notifications-icon-container">
        <div class="relative">
          <unicon name="bell" id="mobile-notifications-icon"/>
          <span id="mobile-notifications-new" v-if="hasNew"></span>
        </div>
      </div>
    </template>
    <b-link :to="{ name: 'welcome' }" id="main-logo-link">
      <img id="main-logo" src="@/assets/logo_bandeau_haut.png" alt="logo Région Centre-Val de Loire, Ministère du travail Haut-commissariat aux compétences et à l'inclusion par l'emploi, Investir l'avenir, Investir dans vos compétences"/>
    </b-link>
    <img id="second-logo" src="../../assets/Ruban_logo_2.png" alt="Identification des Besoins en Compétences des Entreprises"/>
    <div class="nav-button" id="toggle-main-nav" @click="toggleMainNav" v-bind:class="{ active: isMainNavOpen }" v-if="!isUserNavOpen && showMenu">
      <span class="top"></span>
      <span class="middle"></span>
      <span class="bottom"></span>
    </div>
    <div v-bind:class="{ open: isMainNavOpen }" id="main-nav" v-if="showMenu">
      <b-navbar-nav>
        <template v-if="$user.isAuth">
          <b-nav-item @click="toggleMainNav" :to="{ name: 'home' }">Mon espace</b-nav-item>
          <b-nav-item @click="toggleMainNav" to="/visit">Nouvelle visite</b-nav-item>
          <b-nav-item @click="toggleMainNav" to="/mes-visites">Mes visites</b-nav-item>
          <b-nav-item @click="toggleMainNav" to="/carnet-adresses">Carnet d'adresses</b-nav-item>
          <b-nav-item @click="toggleMainNav" to="/entreprises">Entreprises</b-nav-item>
          <b-nav-item @click="toggleNotif" id="notifications-icon-container">
            <unicon name="bell" id="notifications-icon"/>
            <span id="notifications-new" v-if="hasNew"></span>
          </b-nav-item>
        </template>
        <!--<template v-else>
          <b-nav-item @click="toggleMainNav" :to="{ name: 'welcome' }">Accueil</b-nav-item>
          <b-nav-item @click="toggleMainNav" :to="{ name: 'siret_contact_form' }">Contacter les acteurs locaux</b-nav-item>
          <b-nav-item @click="toggleMainNav" :to="{ name: 'contact_form' }">Nous contacter</b-nav-item>
          <b-nav-item @click="toggleMainNav" to="/signin">Inscription</b-nav-item>
        </template>-->
      </b-navbar-nav>
    </div>
    <div id="notifications-container" v-bind:class="{ open: isNotifOpen }">
      <notification-list :open="isNotifOpen" @new="value => setNewNotif(value)"></notification-list>
    </div>
  </b-navbar>
</template>
<script>
import model from '@/views/model.mixin'
import UserMenu from '@/views/UserMenu'
import NotificationList from '@/views/notification/list'

export default {
  name: 'gip.menu',
  mixins: [model('$user')],
  components: { UserMenu, NotificationList },
  data () {
    return {
      isMainNavOpen: false,
      isUserNavOpen: false,
      isNotifOpen: false,
      hasNew: false,
      notifications: []
    }
  },
  props: {
    showMenu: {
      default: () => true
    }
  },
  mounted () {
    this.$user.on('update', () => this.$forceUpdate())
  },
  methods: {
    logout () {
      this.$user.logout()
      window.location.reload()
    },
    toggleMainNav () {
      this.isMainNavOpen = !this.isMainNavOpen
    },
    toggleUserNav () {
      this.isUserNavOpen = !this.isUserNavOpen
    },
    toggleNotif () {
      this.isNotifOpen = !this.isNotifOpen
    },
    hideNotif () {
      if (this.isNotifOpen === true) {
        this.isNotifOpen = false
      }
    },
    setNewNotif (value) {
      this.hasNew = value
    }
  },
  directives: {
    out: {
      bind: function (el, binding, vnode) {
        el.eventSetDrag = function () {
          el.setAttribute('data-dragging', 'yes')
        }
        el.eventClearDrag = function () {
          el.removeAttribute('data-dragging')
        }
        el.eventOnClick = function (event) {
          var dragging = el.getAttribute('data-dragging')
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el === event.target || el.contains(event.target)) && !dragging) {
            // call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.addEventListener('touchstart', el.eventClearDrag)
        document.addEventListener('touchmove', el.eventSetDrag)
        document.addEventListener('click', el.eventOnClick)
        document.addEventListener('touchend', el.eventOnClick)
      },
      unbind: function (el) {
        document.removeEventListener('touchstart', el.eventClearDrag)
        document.removeEventListener('touchmove', el.eventSetDrag)
        document.removeEventListener('click', el.eventOnClick)
        document.removeEventListener('touchend', el.eventOnClick)
        el.removeAttribute('data-dragging')
      }
    }
  }
}
</script>
