<template>
  <b-container fluid>
    <header class="bloc-title">
      <h2 class="bloc-title-content">Entreprise visitées par tous les acteurs locaux enregistrés</h2>
    </header>
    <b-row>
      <b-col>
        <b-form>
          <b-row>
            <b-col cols="12" xl="6">
              <b-form-group label="Entreprise">
                <b-input v-model="siretSearch.name"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6">
              <b-form-group label="Territoire">
                <b-input v-model="siretSearch.zone"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <siret-table :filters="siretFilters" :limit="10" :fields="siretFields" :default-orders="siretOrders"/>
      </b-col>
    </b-row>
    <header class="bloc-title">
      <h2 class="bloc-title-content">Acteurs locaux</h2>
    </header>
    <b-row>
      <b-col>
        <b-form>
          <b-row>
            <b-col cols="12" xl="6">
              <b-form-group label="Nom">
                <b-input v-model="userSearch.lastname"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6">
              <b-form-group label="Prénom">
                <b-input v-model="userSearch.firstname"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6">
              <b-form-group label="Type de structure">
                <b-input v-model="userSearch.organisation"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6">
              <b-form-group label="Territoire">
                <b-input v-model="userSearch.zone"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="6">
              <span class="csv">
                <b-btn @click="onCSV" variant="primary" :disabled="Boolean(csv)">
                  <b-spinner variant="light" type="grow" small v-if="csv"/>
                  <unicon name="file-download"/>
                </b-btn>
              </span>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <user-table :filters="userFilters" :fields="userFields" ref="userTable"/>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import UserTable from '@/views/user/table'
import SiretTable from '@/views/siret/table'
import jsoncsv from 'json-csv'
import debug from 'debug'
import download from '@/views/download'
import encoding from 'encoding'
const log = debug('app')

export default {
  name: 'gip.address',
  components: { UserTable, SiretTable },
  data () {
    return {
      userFilters: [{
        type: '\\App\\Model\\Filter\\EqualsFilter',
        field: 'enabled',
        value: JSON.stringify(true)
      }],
      userSearch: {
        lastname: '',
        firstname: '',
        organisation: '',
        zone: ''
      },
      userFields: [
        { key: 'organisations', label: 'Types de structure', sortable: true },
        { key: 'structure', label: 'Nom de la structure', sortable: true },
        { key: 'mission.name', label: 'Champ d\'intervention', sortable: true },
        { key: 'lastname', label: 'Nom', sortable: true },
        { key: 'firstname', label: 'Prénom', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phone', label: 'Téléphone' },
        { key: 'cityPostcode.postcode.name', label: 'Code postal' },
        { key: 'cityPostcode.city.name', label: 'Ville' },
        { key: 'actions', label: '' }
      ].filter(f => this.$user.roles.includes('ROLE_ADMIN') || !['email', 'phone'].includes(f.key)),
      siretFilters: [{
        type: '\\App\\Model\\Filter\\VisitedFilter'
      }],
      siretSearch: {
        name: '',
        zone: (!this.$user.regionalActor ? this.$user.cityPostcode.city.employmentZone.name : '')
      },
      siretFields: [
        { key: 'name', label: 'Entreprise', sortable: true },
        { key: 'address', label: 'Adresse', sortable: true },
        { key: 'cityPostcode.city.employmentZone.name', label: 'Territoire', sortable: true },
        { key: 'actions', label: '' }
      ],
      siretOrders: [
        { column: '#v.date', direction: 'DESC' }
      ],
      csv: false
    }
  },
  watch: {
    userSearch: {
      handler: function () {
        this.onSubmitUserFilters()
      },
      deep: true
    },
    siretSearch: {
      handler: function () {
        this.onSubmitSiretFilters()
      },
      deep: true
    }
  },
  async mounted () {
    this.onSubmitSiretFilters()
  },
  methods: {
    async onCSV () {
      try {
        this.csv = this.$refs.userTable.request({
          page: 1,
          limit: this.$refs.userTable.count
        })
        const { results } = await this.csv
        const options = {
          fields: [
            {
              name: 'email',
              label: 'email'
            }, {
              name: 'gender',
              label: 'civilité',
              filter: function (value) {
                return { female: 'Mme', male: 'M.' }[value]
              }
            }, {
              name: 'firstname',
              label: 'nom'
            }, {
              name: 'lastname',
              label: 'prénom'
            }, {
              name: 'address',
              label: 'address'
            }, {
              name: 'phone',
              label: 'téléphone',
              filter: function (value) {
                return '\'' + value
              }
            }, {
              name: 'organisations',
              label: 'type de structure',
              filter: function (value) {
                return value.map(o => o.name).join(', ')
              }
            }, {
              name: 'cityPostcode.city.name',
              label: 'ville'
            }
          ].filter(f => this.$user.roles.includes('ROLE_ADMIN') || !['email', 'phone'].includes(f.name))
        }
        const csv = await jsoncsv.buffered(results, options)
        const blob = new Blob([encoding.convert(csv, 'ISO-8859-1')], { type: 'text/plain;charset=ISO-8859-1' })
        download(blob, 'acteurs.csv')
      } catch (err) {
        log(err)
      }
      this.csv = false
    },
    async onSubmitSiretFilters () {
      this.page = 1
      this.siretFilters.splice(
        1,
        this.siretFilters.length,
        {
          type: '\\App\\Model\\Filter\\LikeFilter',
          field: 'name',
          value: JSON.stringify(`%${this.siretSearch.name}%`)
        },
        {
          type: '\\App\\Model\\Filter\\LikeFilter',
          field: 'cityPostcode.city.employmentZone.name',
          value: JSON.stringify(`%${this.siretSearch.zone}%`)
        }
      )
    },
    async onSubmitUserFilters () {
      this.page = 1
      this.userFilters.splice(
        1,
        this.userFilters.length,
        {
          type: '\\App\\Model\\Filter\\LikeFilter',
          field: 'lastname',
          value: JSON.stringify(`%${this.userSearch.lastname}%`)
        },
        {
          type: '\\App\\Model\\Filter\\LikeFilter',
          field: 'firstname',
          value: JSON.stringify(`%${this.userSearch.firstname}%`)
        },
        {
          type: '\\App\\Model\\Filter\\LikeFilter',
          field: 'organisations.name',
          value: JSON.stringify(`%${this.userSearch.organisation}%`)
        },
        {
          type: '\\App\\Model\\Filter\\LikeFilter',
          field: 'cityPostcode.city.employmentZone.name',
          value: JSON.stringify(`%${this.userSearch.zone}%`)
        }
      )
    }
  }
}
</script>
<style>
.csv {
  float: right;
  margin-top: 25px;
}
</style>
