<template>
  <div>
    <h1>S'inscrire</h1>
    <user-form @submit="onSave" v-model="value" v-if="!sent"></user-form>
    <b-alert show v-else>Compte en cours de modération vous serez averti.e par mail à la validation.</b-alert>
  </div>
</template>
<script>
import UserForm from './form'
import User from '@/views/user/model'
import ErrorHandler from '@/views/error/mixin'
import { identityNum } from '@/views/fun'

export default {
  name: 'gip.user.signin',
  components: {
    UserForm
  },
  props: {
    value: {
      type: User,
      default () {
        return new User()
      }
    }
  },
  mixins: [ErrorHandler],
  data () {
    return {
      sent: false
    }
  },
  methods: {
    onSave () {
      this.value.save({
        without: ['enabled', 'roles', 'avatar'],
        reason: identityNum,
        mission: identityNum,
        organisation: identityNum,
        cityPostcode: identityNum,
        childs: {
          reasons: { fn: reason => Number(reason.id) },
          organisations: { fn: organisation => Number(organisation.id) }
        }
      }).then(() => {
        this.sent = true
      }).catch((error) => {
        this.handleError(error)
      })
    }
  }
}
</script>
